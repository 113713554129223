import {
  Box,
  Button,
  Collapse,
  Flex,
  Icon,
  Image,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { IoIosArrowDown } from "react-icons/io"
import { LineItem } from "~/interfaces/entities/autopilot/AccountWithOrders"
import {
  formatCurrency,
  replaceProcessedImageWithProductImageIfNeeded,
} from "~/services/Utils"
import OrderPreviewImages from "../../OrderPreviewImages"

const OrderLineItems = ({
  lineItems,
  currency,
  totalGroups,
  index,
}: {
  lineItems: LineItem[]
  currency: string
  totalGroups: number
  index: number
}) => {
  const { t } = useTranslation()
  const { isOpen, onToggle } = useDisclosure()

  const [firstLineItem] = lineItems
  const [product] = firstLineItem.product

  const { unitPrice } = lineItems.reduce(
    (lineItem, current) => {
      lineItem.unitPrice += Number(current.unitPrice)
      return lineItem
    },
    { unitPrice: 0 }
  )

  const isLastItem = index + 1 === totalGroups
  const lineItemsPreview = lineItems.slice(0, 3)
  const totalLineItems = lineItems.reduce(
    (accumulator, currentLineItem) => accumulator + currentLineItem.quantity,
    0
  )

  return (
    <>
      <Tr bg="gray.100" boxShadow="base" mt={5}>
        <Td pr={3} pl={4}>
          <OrderPreviewImages lineItems={lineItemsPreview} />
        </Td>
        <Td width="100%" maxWidth={1}>
          <Text fontWeight="bold" m={0} isTruncated>
            {product.name}
          </Text>
          <Text m={0}>
            {t(
              "components.account.orders.preview.line-items.OrderLineItems.QuantityShort",
              { totalLineItems }
            )}
          </Text>
        </Td>
        <Td textAlign="right">{formatCurrency(currency, unitPrice)}</Td>
        <Td textAlign="right" px={2}>
          <Button
            id="view-images"
            variant="ghost"
            colorScheme="primary"
            size="sm"
            onClick={onToggle}
            rightIcon={
              <Icon
                as={IoIosArrowDown}
                boxSize={4}
                transform={`rotate(${isOpen ? -180 : 0}deg)`}
                transition="all ease-out .2s"
              />
            }
          >
            {t(
              "components.account.orders.preview.line-items.OrderLineItems.View"
            )}
          </Button>
        </Td>
      </Tr>

      <Tr aria-expanded={isOpen} bg="white">
        <Td
          p={0}
          rounded="md"
          shadow="md"
          width="100%"
          colSpan={5}
          boxShadow="base"
          borderTopRadius={0}
        >
          <ExpandedImagesArea isOpen={isOpen} lineItems={lineItems} />
        </Td>
      </Tr>

      {!isLastItem && (
        <Tr aria-hidden={true} height={5}>
          <Td colSpan={5} p={0}></Td>
        </Tr>
      )}
    </>
  )
}

export default OrderLineItems

export const ExpandedImagesArea = ({
  isOpen,
  lineItems,
}: {
  isOpen: boolean
  lineItems: LineItem[]
}) => {
  return (
    <Collapse in={isOpen} animateOpacity>
      <Flex flexWrap="wrap" p={2}>
        {lineItems.map(lineItem => {
          const lineItemWithImage =
            replaceProcessedImageWithProductImageIfNeeded(lineItem)
          const [processedImage] = lineItemWithImage.processedImage

          return (
            <Flex
              key={lineItem.id}
              alignItems="center"
              p={2}
              minW="135px"
              width={{ base: "100%", sm: "16.666%" }}
            >
              <Image
                src={processedImage.fullUrl}
                maxWidth="100%"
                maxHeight="135px"
                mx="auto"
                boxShadow="base"
                borderRadius="sm"
              />
            </Flex>
          )
        })}
      </Flex>
    </Collapse>
  )
}
