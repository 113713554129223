import { HStack, Table, Tbody, Td, Text, Thead, Tr } from "@chakra-ui/react"
import { Dictionary, PrinticularLineItem } from "@jackfruit/common"
import { groupBy, orderBy } from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { OrderEntity } from "~/interfaces/entities/Order"
import {
  OrderSummaryEntity,
  RemoteOrderSummaryLineItem,
} from "~/interfaces/entities/OrderSummary"
import { formatCurrency } from "~/services/Utils"

export interface SummaryLine {
  label: string
  total: number
  discount?: number
  quantity: number
}

interface PriceProps {
  lineItem: SummaryLine
  currency: string
}

const PriceWithDiscount: React.FC<PriceProps> = ({ lineItem, currency }) => {
  const { discount, total } = lineItem

  if (!discount) {
    return <Text>{formatCurrency(currency, total)}</Text>
  } else {
    return (
      <HStack spacing={2} justifyContent="flex-end">
        <Text color="red.500">{formatCurrency(currency, total)}</Text>
        <Text as="s">{formatCurrency(currency, total + discount)}</Text>
      </HStack>
    )
  }
}

const sortAndGroupLineItems = (
  lineItems: RemoteOrderSummaryLineItem[] | PrinticularLineItem[]
) => {
  const groupedLineItems = groupBy(lineItems, "product.id") as Dictionary<
    RemoteOrderSummaryLineItem[]
  >

  // sort by product short description
  const sortedLineItems = orderBy(Object.values(groupedLineItems), [
    groupedLineItem => groupedLineItem[0].product.shortDescription,
  ])

  return sortedLineItems
}

export const SummaryLineItems = ({
  summaryLines,
  currency,
}: {
  summaryLines: SummaryLine[]
  currency: string
}) => {
  const { t } = useTranslation()

  return (
    <Table colorScheme="facebook">
      <Thead>
        <Tr>
          <Td
            fontSize={{ base: "sm", sm: "md" }}
            border="none"
            px={0}
            pt={0}
            textAlign="left"
          >
            <Text fontWeight="bold">
              {t("components.checkout.OrderSummaryLineItems.Products")}
            </Text>
          </Td>
          <Td
            fontSize={{ base: "sm", sm: "md" }}
            border="none"
            px={0}
            pt={0}
            textAlign="center"
          >
            <Text fontWeight="bold">
              {t("components.checkout.OrderSummaryLineItems.Quantity")}
            </Text>
          </Td>
          <Td
            fontSize={{ base: "sm", sm: "md" }}
            border="none"
            px={0}
            pt={0}
            textAlign="right"
          >
            <Text fontWeight="bold">
              {t("components.checkout.OrderSummaryLineItems.TotalPrice")}
            </Text>
          </Td>
        </Tr>
      </Thead>
      <Tbody>
        {summaryLines.map((lineItem, index) => {
          return (
            <Tr key={index}>
              <Td border="none" px={0}>
                <Text fontSize="sm">{lineItem.label}</Text>
              </Td>
              <Td border="none" px={0} textAlign="center">
                <Text fontSize="sm">{lineItem.quantity}</Text>
              </Td>
              <Td border="none" px={0} textAlign="right">
                <PriceWithDiscount lineItem={lineItem} currency={currency} />
              </Td>
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}

interface OrderSummaryProps {
  order: OrderSummaryEntity | OrderEntity
}

const OrderSummaryLineItems: React.FC<OrderSummaryProps> = ({ order }) => {
  const { lineItems, currency } = order.data

  const groupedAndSortedLineItems = sortAndGroupLineItems(lineItems)

  const summaryLines: SummaryLine[] = groupedAndSortedLineItems.map(
    lineItemsGroup => {
      return lineItemsGroup.reduce(
        (line, current) => {
          line.quantity += current.quantity
          line.total += current.totalFloat
          line.label = current.product.shortDescription

          return line
        },
        { quantity: 0, total: 0, label: "" }
      )
    }
  )

  return <SummaryLineItems summaryLines={summaryLines} currency={currency} />
}

export default OrderSummaryLineItems
