import { Badge, Box } from "@chakra-ui/react"
import React from "react"

interface Props {
  status: string
}

const OrderStatus: React.FC<Props> = ({ status }) => {
  const color =
    {
      failed: "red",
      cancelled: "yellow",
      completed: "green",
    }[status] ?? "blue"

  return (
    <Box>
      <Badge rounded="full" colorScheme={color}>
        {status}
      </Badge>
    </Box>
  )
}

export default OrderStatus
