import { Box, Image, LayoutProps } from "@chakra-ui/react"
import React, { useState } from "react"
import { LineItem } from "~/interfaces/entities/autopilot/AccountWithOrders"
import { replaceProcessedImageWithProductImageIfNeeded } from "~/services/Utils"

const ROTATION_ANGLE = 12

interface Props extends Pick<LayoutProps, "maxWidth" | "maxHeight"> {
  lineItems: LineItem[]
}

const OrderPreviewImages: React.FC<Props> = ({
  lineItems,
  maxWidth = "150px",
  maxHeight = "60px",
}) => {
  const totalInPreview = lineItems.length
  const reverseAngle =
    totalInPreview > 1
      ? ROTATION_ANGLE * totalInPreview - ROTATION_ANGLE / 2
      : 0

  const lineItemsWithImages = lineItems.map(lineItem => {
    return replaceProcessedImageWithProductImageIfNeeded(lineItem)
  })

  // Order asc per width - Largest at the back
  const sortedLineItems = lineItemsWithImages.sort((currentItem, nextItem) => {
    const { width: currentItemWidth } = currentItem.processedImage[0]
    const { width: nextItemWidth } = nextItem.processedImage[0]

    return currentItemWidth > nextItemWidth ? 1 : -1
  })

  return (
    <Box position="relative" transform={`rotate(-${reverseAngle}deg)`}>
      {sortedLineItems.map((lineItem, index) => {
        const degree = totalInPreview > 1 ? ROTATION_ANGLE * (index + 1) : 0

        return (
          <PreviewImage
            src={lineItem.processedImage[0].fullUrl}
            maxHeight={index === 0 ? maxHeight : "100%"}
            maxWidth={maxWidth}
            key={lineItem.id}
            degree={degree}
            index={index}
          />
        )
      })}
    </Box>
  )
}

export default OrderPreviewImages

interface PreviewImageProps
  extends Pick<LayoutProps, "maxWidth" | "maxHeight"> {
  src: string
  degree: number
  index: number
}

const PreviewImage: React.FC<PreviewImageProps> = ({
  src,
  maxWidth,
  maxHeight,
  degree,
  index,
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [href, setHref] = useState(src)

  const onError = () => {
    setHref("/images/placeholder-failed.svg")
  }

  const onLoad = () => {
    setIsLoaded(true)
  }

  return (
    <Image
      src={href}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      boxShadow="base"
      bg="#fff"
      borderRadius="sm"
      p={!isLoaded ? 2 : 0}
      position={index === 0 ? "relative" : "absolute"}
      top={index === 0 ? "auto" : "50%"}
      left={index === 0 ? "auto" : "50%"}
      fallbackSrc="/images/placeholder.svg"
      onError={onError}
      onLoad={onLoad}
      transform={
        index === 0
          ? `rotate(${degree}deg)`
          : `translate(-50%, -50%) rotate(${degree}deg)`
      }
    />
  )
}
