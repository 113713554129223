import { Text } from "@chakra-ui/react"
import React from "react"
import { Order as AccountWithOrdersOrder } from "~/interfaces/entities/autopilot/AccountWithOrders"
import { Order } from "~/interfaces/entities/autopilot/Order"

// todo: refactor Order and AccountWithOrdersOrder to share a common type
export const OrderHeader: React.FC<{ order: Order | AccountWithOrdersOrder }> =
  ({ order }) => (
    <>
      {order.displayOrderIds!.map(id => (
        <Text key={id}>#{id}</Text>
      ))}
    </>
  )
