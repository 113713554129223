import { Flex, Skeleton } from "@chakra-ui/react"
import { groupBy, values } from "lodash"
import React from "react"
import {
  SummaryLine,
  SummaryLineItems,
} from "~/components/checkout/order-summary/OrderSummaryLineItems"
import OrderSummaryTotals from "~/components/checkout/order-summary/OrderSummaryTotals"
import { Order } from "~/interfaces/entities/autopilot/Order"

interface Props {
  order?: Order
  isLoading: boolean
}

const OrderPreviewSummary: React.FC<Props> = ({ order, isLoading }) => {
  if (isLoading) {
    return (
      <Flex alignItems="center" justifyContent="flex-end" w="100%">
        <Skeleton
          mt={0}
          height="180px"
          startColor="gray.50"
          endColor="gray.100"
          w="100%"
        />
      </Flex>
    )
  }

  const groupedLineItems = values(groupBy(order?.lineItems, "product.[0].id"))
  const summaryLines: SummaryLine[] = groupedLineItems.map(lineItemsGroup => {
    return lineItemsGroup.reduce(
      (line, current) => {
        line.quantity += current.quantity
        line.total += Number(current.total)
        line.label = current.product[0].shortDescription

        return line
      },
      { quantity: 0, total: 0, discount: 0, label: "" }
    )
  })

  return (
    <>
      <SummaryLineItems
        summaryLines={summaryLines}
        currency={order!.currency}
      />
      <OrderSummaryTotals
        freight={order!.freight}
        freightHuman={order!.freightHuman}
        taxInclusive={order!.taxInclusive}
        taxTotal={order!.taxTotal}
        taxTotalHuman={order!.taxTotalHuman}
        total={order!.total}
        totalHuman={order!.totalHuman}
        couponCode={order!.couponCode}
        discount={order!.discount}
        discountHuman={order!.discountHuman}
        fulfillment={order!.fulfillmentType}
      />
    </>
  )
}

export default OrderPreviewSummary
